<template>
  <div>
    <div class="pattern"></div>
    <slot />
  </div>
</template>

<style scoped>
.pattern {
  background: url(~/assets/landingpage/hero/hero-bg.webp) no-repeat center;
  background-size: cover;
  height: 100dvh;
  width: 100dvw;
  position: fixed;
  z-index: -1;
}
</style>
