<template>
  <nav
    class="flex gap-5 pt-2 text-sm text-white md:justify-center md:gap-16 md:text-base portrait:pl-4 portrait:pr-2 max-md:portrait:justify-between landscape:justify-center"
  >
    <a
      v-for="(item, index) in navItems"
      :key="index"
      :title="item.title"
      :href="item.href"
      class="bg-growing-underline-a capitalize"
      :class="{
        'bg-growing-underline-active font-bold': currentPath.startsWith(
          item.href
        ),
      }"
      >{{ item.title }}</a
    >
  </nav>
</template>

<script lang="ts" setup>
const pageContext = usePageContext();
const currentPath = toRef(pageContext, "urlPathname");

const navItems: { title: string; href: string }[] = [
  {
    title: "Intro",
    href: "/intro",
  },
  {
    title: "Vision",
    href: "/vision",
  },
  {
    title: "Team",
    href: "/team",
  },
  // {
  //   title: "Artikel",
  //   href: "/artikel",
  // },
  {
    title: "F.A.Q.",
    href: "/faq",
  },
];
</script>
